import React from 'react';

const Footer = () => {
  return (
    <div className=" m-0 bg-purple-900 text-white text-center">
      <p>Build with ❤️ by Nihal </p>
    </div>
  );
};

export default Footer;
